@import 'variables';
@import 'mixins';

.news-detail {
  padding: 3rem;

  .news-detail-category {
    display: flex;
    align-items: center;

    @include mQ(992px) {
      flex-direction: column;
      align-items: start;
    }

    @include mQ(600px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .news-detail-category-text {
    background-color: $blue;
    color: $white;
    padding: 0.5rem 0.5rem;
    border-radius: 8px;
    font-weight: bold;
    margin-right: 3rem;

    @include mQ(992px) {
      margin-bottom: 1rem;
      text-align: center;
      margin-right: 0;
    }

    @include mQ(600px) {
      width: 100%;
    }
  }

  h5, h1 {
    color: $blue;
  }
}
