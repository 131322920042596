@import 'variables';
@import 'mixins';


.error-page{
    background-image: url('../assets/gradient-bg.svg');
    @include mQ(992px){
        background-image: url('../assets/gradient-bgmob.svg');
    }

    background-position: bottom center;
    background-size: cover;
    background-repeat: no-repeat;

    background-color: $blue;
    height: 60vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    padding-top: $space-6xl-18xl;
    h1{
        color: $white;
    }

    a{
        @include buttonWhite;
        margin: 50px;
        width: 500px;
        max-width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover{
            color:$white;
        }
    }
}
