@import "variables";

@mixin background-cover {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

@mixin mQ($arg) {
    @media screen and (max-width: $arg) {
        @content;
    }
}

@mixin mQ-min($arg) {
    @media screen and (min-width: $arg) {
        @content;
    }
}

@mixin clearFix {
    &:after {
        content: "";
        display: block;
        clear: both;
    }
}

@mixin selectUnderline($color) {
    position: relative;
    &::after{
        position:absolute;
        bottom:0px;
        left: 0px;
        content: "";
        width: 0px;
        border-bottom: 2px solid $color;
        transition: width 250ms;
    }

    &:hover::after,&.active::after,&:active::after{
       width: 100%; 
    }
}

@mixin buttonBlue {

    background-color: $blue;
    color: $white;
    border: 0px;
    transition: all 100ms;
    padding: 15px 25px;
    font-weight: 500;
    &:focus{
        outline:none;
    }
    
    &:hover, &:active{
        background-color: $dark-blue;
        
    }
    
}

@mixin buttonWhite {
    
    background-color: $white;
    color: $dark-blue;
    border: 0px;
    transition: all 100ms;
    padding: 15px 25px;
    font-weight: 500;
    &:focus{
        outline:none;
    }

    &:hover, &:active{
        background-color: $blue;
        
    }
    
}


@mixin fadeInUp{
    transform: translateY(10%);
    margin-top: 30px;
    opacity: 0;
    &.show{
        transition:all 500ms ease;
        // margin-top: 0px;
        // animation: fadeIn 300ms ease-in;
        transform: translateY(0%);
        opacity: 1;
    }

    @keyframes fadeIn {
        0%{
            transform: translateY(10%);
            opacity: 0;

        }

        100%{
            transform: translateY(0%);
            opacity: 1;
        }
    }
} 