@import 'variables';
@import 'mixins';

.news-header {
  .title {
    color: $white;
    padding: $space-9xl 0 $space-9xl 0;

    .title-left {
      padding: $space-5xl 0;
      background-color: $blue;

      @include mQ(992px) {
        text-align: center;
      }

      h1 {
        padding-left: calc(2 / 12 * calc(100%));

        @include mQ(992px) {
          padding-left: 0;
        }
      }
    }

    .title-right {
      display: flex;
      background-color: $blue;

      @include mQ(992px) {
        display: none;
      }
    }
  }

  .news-header-nav {
    display: flex;
    align-items: center;
    margin-left: 3rem;

    @include mQ(992px){
      margin-left: 1rem;
    }

    @include mQ(600px) {
      flex-direction: column;
      margin-left: 2rem;
    }

    .category-text {
      background-color: $blue;
      border-radius: 8px;
      margin-right: 2rem;
      padding: 0.5rem 1rem;
      color: $white;
      font-weight: bold;

      @include mQ(992px) {
        width: 100%;
        text-align: center;
        margin-top: 0.3rem;
      }
    }
  }

}
