@import 'variables';
@import 'mixins';

.marginal {

  overflow: hidden;

  &.header {
    z-index: 100;
    background-color: $blue;
    position: relative;
    overflow: hidden;

    .bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 99;

      img {
        width: 100%;
        height: auto;
      }
    }

    .quick-info {
      position: relative;
      background-color: $white;
      padding: 10px 0;
      z-index: 101;

      ul {
        margin: auto;

        li, a {
          font-size: $step--1;
          font-weight: 400;
          margin-left: 25px;

          svg {
            margin-right: 10px;
            transition: all 200ms linear;
          }

          &:hover {
            svg {
              stroke: $blue;
              stroke-width: 1.5px;
            }
          }
        }

        a {
          margin-left: 0;
        }
      }
    }

    .head-content {
      padding: $space-2xl 0;
      margin: auto;
      z-index: 102;

      .logo {
        width: fit-content;

        svg {
          fill: $white;
          margin-right: 10px;
          width: clamp(1.38rem, calc(0.08rem + 8.67vw), 7.88rem);
          height: auto;
        }

        h5, p {
          color: $white;
          font-weight: normal;
          text-transform: uppercase;
          margin-bottom: 0;
          font-size: clamp(0.50rem, calc(0.25rem + 1.67vw), 1.75rem);
          height: fit-content;
        }

        p {
          font-size: 18px;
        }
      }

      .navbar-toggler {
        border-radius: 50%;
        border: 1px solid $white;
        width: clamp(1rem, calc(0.98rem + 5vw), 2.63rem);
        height: clamp(1rem, calc(0.98rem + 5vw), 2.63rem);
        padding: clamp(0rem, calc(0.1vw), 0.25rem) clamp(0rem, calc(0.23rem + 0.34vw), 0.44rem);

        &:focus {
          outline: none;
        }

        svg {
          width: 100%;
          height: auto;
          stroke: $white;
        }

      }

      .navbar-collapse {
        padding: 0;
        @include mQ(992px) {

          padding-top: $space-2xl;
          // background-color: $white;
        }
      }

      ul {
        li {
          color: $white;
          @include mQ-min(992px) {
            margin-left: 25px
          }

          &.nav-item {
            @include selectUnderline($white);

            &:hover {
              color: $dark-blue;
            }
          }

          a {
            padding: 0;
            color: $white;

            &:hover {
              color: $dark-blue;
            }
          }

          &:not(.nav-item) {
            font-weight: 400;

            svg {
              margin-right: 10px;
            }
          }
        }

        @include mQ(992px) {
          width: 100%;
          text-align: center;
          li {
            align-items: center;
            justify-content: center;
            margin-bottom: 18px;
          }
        }
      }
    }


  }

  &.footer {
    // background-color: $white;
    padding-top: clamp(2.88rem, calc(2.39rem + 2.07vw), 4.25rem);;
    margin-bottom: clamp(2.88rem, calc(2.39rem + 2.07vw), 4.25rem);;

    .content {
      margin-bottom: clamp(2.88rem, calc(2.39rem + 2.07vw), 4.25rem);;

      .logo {
        margin-bottom: $space-4xl-3xl;
        width: fit-content;

        svg {
          width: 35%;
          height: auto;
          fill: $dark-blue;
          @include mQ-min(992px) {
            height: 100%;
            width: auto;
            margin-right: 10px;
          }
        }

        h4, p {
          font-weight: normal;
          text-transform: uppercase;
          margin-bottom: 0;
        }

        p {
          font-size: 18px;
        }

        &:hover {
          svg {
            fill: $blue;
          }
        }
      }

      p.desc {
        font-size: $step--1;
        @include mQ-min(992px) {
          width: 70%;
        }
      }

      .list {
        padding: 0;
        @include mQ-min(992px) {
          padding: $space-2xs-4xl;
          border-left: 1px solid $dark-blue;
        }

        * {
          font-size: $step--1;
        }

        .bold {
          font-weight: bold;
        }

        ul {
          padding: 0;
          line-height: 37px;
        }

        p {
          margin-bottom: $space-2xs-3xl;
        }

        svg {
          margin-right: 10px;
        }
      }
    }

    .info {
      font-size: $step--1;

      div:nth-child(2) {
        a {
          font-weight: bold;
        }

        @include mQ-min(992px) {
          padding-left: $space-2xs-4xl;
        }
      }
    }
  }
}
