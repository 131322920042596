@import 'variables';
@import 'mixins';


.contact-page {
    .title {
        color: $white;
        padding: $space-9xl 0 $space-9xl 0;

        .title-left {
            padding: $space-5xl 0;
            background-color: $blue;

            @include mQ(992px) {
                text-align: center;
            }

            h1 {
                padding-left: calc(2/12 * calc(100%));

                @include mQ(992px) {
                    padding-left: 0;
                }
            }
        }

        .title-right {
            display: flex;
            background-color: $blue;

            @include mQ(992px) {
                display: none;
            }
        }
    }

    .contact-section {
        form {
            width: 100%;

            .message {
                margin-bottom: $space-s;
            }

            h3 {
                margin-bottom: $space-5xl;
            }

            input,
            textarea {
                padding: $space-m;
                margin-bottom: $space-5xl;
                border-width: 2px;
            }
        }

        .vertical-line {
            position: relative;
            width: 400px;
            height: 702px;

            @include mQ(992px) {
                display: none;
            }

            .line {
                position: absolute;
                left: 49%;
                top: 0;
                bottom: 0;
                width: 1px;
                background-color: $grey;
                z-index: 1;
            }

            .word-wrapper {
                text-align: center;
                position: absolute;
                left: 0;
                right: 0;
                top: 45%;
                z-index: 2;

                .word {
                    color: $grey;
                    padding: $space-s 0;
                    font-size: $step-1;
                    background-color: $white;
                }
            }
        }

        .horizontal-line {
            text-align: center;
            color: $grey;
            width: 100%;
            padding-bottom: $space-5xl;

            @include mQ-min(992px) {
                display: none;
            }

            p {
                padding: 0;
                margin: 0;
                line-height: 0;
                border-bottom: 1px solid $grey;
                span {
                    background-color: $white;
                    padding: 0 $space-2xl;
                    font-size: $step-1;
                }
            }
        }

        .info-section {
            h3 {
                margin-bottom: $space-5xl;
            }

            div {
                margin-bottom: $space-9xl;

                p,
                a {
                    font-size: $step-1;
                    font-weight: 400;
                }

                h5 {
                    margin-bottom: $space-xl;
                }

                svg {
                    margin-right: $space-2xl;
                }
            }
        }
    }

    #map {
        margin: $space-9xl 0;
        iframe{
            width: 100%;
            height: 450px;
            @include mQ-min(992px){
                height: 700px;
            }
        }
        .leaflet-container {
            width: 100vw;
            height: 700px;
        }
    }
}