@import 'variables';
@import 'mixins';


.home {


  .bg-watermark {
    position: absolute;
    z-index: -1;
    overflow: hidden;
  }

  a {
    text-transform: uppercase;
    // font-variant: small-caps;
  }

  .hero {
    position: relative;
    overflow: hidden;
    @include mQ(992px) {
      padding: 50px 0;
    }
    @include mQ-min(992px) {
      min-height: 90vh;
    }

    .hero-bg {
      background-position: center;
      background-size: cover;
      position: absolute;
      display: block;
      z-index: 1;
      top: 0;
      left: 0;
      scale: 0.5;
      transition: all 400ms ease-in-out;
      // height: 100%;
      // width: auto;


      &.active {
        scale: 1;
        width: 100%;
        height: 100%;
      }
    }

    .content-container {
      position: relative;
      z-index: 10;
      width: 100%;
      display: flex;

      & > div {
        background-color: $blue-60;
      }

      .slides-container {
        overflow: hidden;
        padding: $space-4xl-6xl;
        @include mQ-min(992px) {
          padding-left: calc(1 / 12 * calc(100%));
        }

      }

      .content-item {
        overflow: hidden;
        transition: all 350ms ease-in;

        * {
          color: $white;
        }

        p, h3 {

          font-size: $step-1;
          // line-height: 24px;
          padding-bottom: $space-s-m 0px;
        }


        a {

          font-size: $step-0;
          @include buttonWhite;
          display: flex;
          justify-content: center;
          align-items: center;
          width: fit-content;
          padding: 15px 50px;

          &:hover, &:active {
            text-decoration: none;
          }
        }

        .content {

          position: absolute;
          scale: 0;
          overflow: hidden;
          transition: scale 200ms ease-in, width 350ms ease-in;
          display: flex;
          flex-direction: column;
          justify-content: start;
          align-items: start;


          @include mQ(992px) {

            text-align: center;
            justify-content: center;
            align-items: center;
          }
        }

        &.active {
          .content {

            scale: 1;
            position: relative;
          }
        }


      }

      .block {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
      }
    }
  }

  .services {
    position: relative;
    padding: $space-6xl-18xl 0;

    @include mQ(992px) {
      overflow: hidden;
    }

    .animated {
      @include fadeInUp
    }

    .bg-watermark {
      top: -10%;
      left: -20%;
      width: 75%;

      img {
        width: 100%;
        height: auto;
      }
    }

    .content {
      height: 100%;
      width: 45%;
      @include mQ(992px) {
        width: 100%;
        margin-top: $space-6xl;
      }

      h2 {
        margin-bottom: $space-4xl-6xl;
      }

      p {
        font-size: $step-0;
      }

      ul {
        padding: 0;
        width: 100%;
        margin: $space-6xl 0;
      }

      li {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        margin-bottom: 15px;
        width: 100%;

        p {
          width: calc(100% - 70px);
          margin-bottom: 0;
        }

        svg {
          width: $step-2;
          height: auto;
          margin-right: 35px;
          stroke-width: 4px;

        }
      }

      a {
        font-size: $step-0;
        @include buttonBlue;
        display: flex;
        justify-content: center;
        align-items: center;
        width: fit-content;
        padding: 15px 50px;

        &:hover, &:active {
          text-decoration: none;
        }

        svg {
          margin-left: 30px;
        }
      }

      .iso-certified {
        max-width: 250px;
        background-color: #4BA1F0;
        margin-left: -2rem;

        @include mQ(992px) {
          margin-left: 0;
          align-self: center;
        }
      }
    }

    .img-grid {
      --gap: 15px;
      --num-cols: 2;
      --row-height: 260px;
      margin-right: $space-9xl;
      display: grid;
      grid-template-columns: repeat(var(--num-cols), 1fr);
      grid-auto-rows: var(--row-height);
      gap: var(--gap);
      width: 50%;
      min-width: 320px;
      min-height: 300px;

      @include mQ(992px) {
        width: 100%;
        min-width: fit-content;
        min-height: fit-content;
        margin-bottom: $space-9xl;
        margin-right: 0;
      }

      img {
        border-radius: 22px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .stretched {
        grid-column: 2 / 3;
        grid-row: 1 / 3;
      }
    }


  }


  .testimonials {
    position: relative;
    padding-top: $space-6xl-18xl;
    padding-bottom: $space-6xl-18xl;
    overflow: hidden;
    background-color: $blue;
    color: $white;
    z-index: 0;

    h2 {
      padding-left: calc(1 / 12 * calc(100%));
    }

    a {
      color: $white;
      margin-right: calc(1 / 12 * calc(100%));
    }

    .bg-watermark {
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        @include mQ(992px) {
          height: 100%;
          width: auto;
        }
      }
    }

    .slide-container {

      overflow: hidden;
      width: 100%;
      position: relative;
      margin: $space-4xl-6xl 0;

      .slide {
        padding-bottom: 25px;
        display: flex;
        overflow-x: scroll;
        flex-wrap: nowrap;
        scroll-snap-type: x mandatory;

        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }


      }

      .testimonial-item {
        background-color: $white;
        padding: 25px;
        color: $black;
        font-style: italic;
        width: 700px;

        p {
          font-size: $step--1;
        }

        img {
          width: 20%;
          height: auto;
        }

        .desc {
          width: 75%;
        }

        @include mQ(992px) {
          width: 85%;
          img {
            width: 30%;
            height: auto;
          }
          .desc {
            width: 65%;
          }
        }

        .credit {
          font-weight: bold;

          p {
            margin-bottom: 0;

            &.info {
              color: $grey;
            }
          }
        }
      }
    }
  }
}
