@import 'variables';
@import 'mixins';


.projects{
    position: relative;
    h2,h5{
        text-transform: capitalize;
    }
    .bg-watermark{
        position: absolute;
        z-index: -1;
        overflow: hidden;
    }
    .section-1{
        background-color: $blue;
        padding: 50px 0px; 
        position: relative;
        z-index: 0;
        .bg-watermark{
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            img{
                height: 110%;
            }
        }


        .title{
            position: relative;
            margin-bottom: $space-l-4xl;
            &>div{
                background-color: $white;
            }
            .content{
                color:$blue;
                padding: $space-s-3xl 0px;

                @include mQ-min(992px){
                    padding-left: calc( 1/12 * calc(100% ));
                }
            }
            
            .block{
                position: absolute;
                right: 0px;
                top:0px;
                bottom: 0px;
            }
        }

        .slide{
            margin: auto;
            margin-bottom: $space-6xl-18xl;
            .content{
                overflow: hidden;
                border-radius: 12px;
                .image{
                    height: 550px;
                    background-position: center;
                    background-size: cover;
                    img,video{
                        margin: auto;
                        width: auto;
                        height: 100%;
                    }
                    
                    
                }
                .video-container{
                    background-color: $white;
                    overflow: hidden;
                    position: relative;
                    width: 100%;
                    height: 550px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    video{
                        position: absolute;
                        min-height: 550px;
                        min-width: 130%;
                    }
                }
                
                .desc{
                    background-color: $white;
                    @include mQ-min(992px){
                        height: 550px;
                    }
                    &>div{
                        margin: auto;
                        width: 100%;
                        padding: 25px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    dl{
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        &>div{
                            width: 100%;
                        }
                        dt {
                            float: left;
                            clear: left;
                            font-weight: bold;
                            color: $dark-blue;
                            width: 40%;
                          }
                          dd {
                            margin: 0px;
                          }
                    }
                }
            }

            .counter{
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center;
                 margin-top: $space-2xs-4xl;
                .dot{
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background-color: $white-30;
                    &.active,&:hover{
                        background-color: $white;
                    }
                }
            }
        }

    }
    
    .section-2{
        background:linear-gradient(180deg, rgba(206, 206, 206, 0.15) 0%, rgba(255, 255, 255, 0) 100%);
        padding:0px;
        position: relative;
        padding-bottom: $space-6xl-18xl;
        @include mQ-min(992px){
            padding-top: 200px;
        }
        
        .tabs{
            margin: auto auto;
            margin-bottom: $space-4xl-6xl;
            @include mQ-min(992px){
                position: absolute;
                width: 100%;
                top: calc( 0px - clamp(6.56rem, calc(5.31rem + 5.35vw), 10.13rem) / 2 );
                left: 0px;

            }
            &>div{
    
                width: 50%;
                max-width: 365px;
                height: clamp(6.56rem, calc(5.31rem + 5.35vw), 10.13rem);
                background-color: $white;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                transition: all 300ms ease-in-out;
                *{
                    font-weight: normal;
                    color: $blue;
                }
                
                &.active{
    
                    width: calc(50% + 10px);
                    max-width: calc(365px + 10px);
                    background-color: $dark-blue;
                    *{
                        font-weight: bold;
                        color: $blue;
                    }
                }
                
                &:hover{
                    width: calc(50% - 5px);
                    background-color: $dark-blue;
                    max-width: calc(365px - 5px);
                }
    
            }
        }
        
        
        .list{
            
            
            button{
                background:none;
                border:none;
                padding: 0px;
                &::after{
                    display: none;

                }
                svg{
                    margin-right: 20px;
                }

                &:hover,&:active,&:focus{
                    outline: none;
                    border:none;
                }
                &:hover{
                    background-color: $dark-blue-5;
                }
            }

            dl{
                dt {
                    float: left;
                    clear: left;
                    font-weight: bold;
                    color: $dark-blue;
                    width: 20%;
                    font-weight: bold;
                  }
                  dd {
                    margin: 0 0 0 110px;
                    padding: 0 0 0.5em 0;
                  }
            }
            *{
                border: 0px;
                background: none;
            }

            .list-item{
                border-bottom: 1px solid $dark-blue-25;
                margin-bottom: $space-4xl-3xl;
                padding-bottom: $space-s-m;
                .accordion-item{
                    border: none;
                    *{
                        border:none;
                        outline: none;
                        &::after{
                            border:none;
                        }
                    }
                }

                h2,button{
                    outline: none;
                    border:none;
                    font-weight: bold;
                    color: $dark-blue;
                }

                svg{
                    transform: rotate(180deg);
                    transition: all 200ms ease-in-out;
                }
                .collapsed{
                    svg{
                        transform: rotate(0deg);
                    }
                }

                transform: translateX(30%);
                opacity: 0;
                transition: all 350ms ease-in-out;

                &.show{
                    transform: translateX(0%);
                    opacity: 1;
                }
            }
        }
    }
    .bg-watermark.section2{
        right: -10%;
        bottom: -5%;
    }
}