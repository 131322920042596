@import 'variables';
@import 'mixins';

.services-page {
   
    color: white;
    width: 100%;
    overflow: hidden;
    

    .title {
        color: $white;
        padding: $space-9xl 0 $space-9xl 0;
        margin-bottom: $space-6xl-18xl;
        .title-left {
            padding: $space-5xl 0;
            background-color: $blue;

            @include mQ(992px) {
                text-align: center;
            }

            h1 {
                padding-left: calc(2/12 * calc(100%));

                @include mQ(992px) {
                    padding-left: 0;
                }
            }
        }

        .title-right {
            display: flex;
            background-color: $blue;

            @include mQ(992px) {
                display: none;
            }
        }
    }

    .card-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: $space-10xl;
        
        @include mQ(768px){
            grid-template-columns: repeat(1,1fr);
            justify-content: center;
        }
        
        
        
        .card {
            background-color: $blue;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 320px;
            min-height: 200px;
            border-radius: 20px;
            margin-bottom: calc( $space-6xl-18xl + 75px);
            position: relative;
            padding: $space-6xl-18xl $space-4xl-6xl;


            .tick {
                position: absolute;
                width: 100px;
                height: 100px;
                top: -50px;
                transition: all 200ms ease-in;
            }

            p {
                font-size: $step-1;
                line-height: $space-xl;
                text-align: center;
                transition: all 150ms ease-in;
                text-transform: capitalize;
            }


            opacity: 0;
            transform: translateY(-40px);
            transition: all 200ms ease-in-out;
    
            &.show{
                opacity: 1;
                transform: translateY(0px);
            }

            &:hover{
                p{
                    font-weight: bold;
                }
                svg{
                    stroke-width: 1px;
                }
            }
        }


        
        

        @include mQ-min(calc( 100vw / 6 + 640px )){
            .show:nth-child(n){
                transition-delay: 0ms;
            }
            .show:nth-child(2n){
                transition-delay: 100ms;
            }
            
        }
        
        @include mQ-min(calc( 100vw / 6 + 3 * 320px )){
            .show:nth-child(n){
                transition-delay: 0ms;
            }
            .show:nth-child(2n){
                transition-delay: 100ms;
            }
            .show:nth-child(3n){
                transition-delay: 200ms;
            }
            
        }
        
        @include mQ-min(calc( 100vw / 6 + 4 * 320px )){
            .show:nth-child(n){
                transition-delay: 0ms;
            }
            .show:nth-child(2n){
                transition-delay: 100ms;
            }
            .show:nth-child(3n){
                transition-delay: 200ms;
            }
            .show:nth-child(4n){
                transition-delay: 300ms;
            }

        }
        
    }
}