@use 'variables.scss';
@import 'variables.scss';
@import 'mixins.scss';

* {
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    ::-webkit-scrollbar {
        width: 7px;
        height: 100%;
    }

    ::-webkit-scrollbar-track {
        background-color: $grey-30;
        transition: background-color linear 300ms;
        border-radius: 5px;

        &:hover {
            background-color: $grey;
        }

    }

    ::-webkit-scrollbar-thumb {
        background-color: $blue-35;
        transition: background-color linear 300ms;
        border-radius: 5px;

        &:hover {
            background-color: $blue;
        }
    }
}

body {
    background-color: $white  !important;
    font-size: $step-0;
    font-weight: normal;
    overflow-y: scroll;
    overflow-x: hidden;
    max-width: 2160px;
    

    a {
        font-size: $step-0;
        text-decoration: none;
        font-weight: 500;
        color: $dark_blue;
        transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
        cursor: pointer;
        &:hover,
        &:active {
            font-weight: 600;
            color: $blue
        }
    }

    li {
        list-style: none;
        font-size: $step-0;
        font-weight: normal;
    }

    h1,h2,h3,h4,h5{
        font-weight: bold;
    }

    h1{
        font-size: $step-5;
    }
    h2{
        font-size: $step-4;
    }
    h3{
        font-size: $step-3;
    }
    h4{
        font-size: $step-2;
    }
    h5{
        font-size: $step-1;
    }

    button,
    .button {
        @include buttonBlue
    }

    // button.secondary,
    // .button-secondary {
    //     @include buttonSecondary
    // }

    // button.border,
    // .button-border {
    //     @include buttonBorder
    // }

    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
            monospace;
    }
}