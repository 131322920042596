@import 'variables';
@import 'mixins';

.news {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 3rem;

  @include mQ(992px) {
    grid-template-columns: 1fr;
    padding: 1rem;
  }

  .news-article {
    background-color: $blue;
    margin: 0 2rem 2rem 0;
    padding: 2rem;
    color: $white;
    border-radius: 8px;

    @include mQ(992px) {
      margin: 2rem 0;
    }

    .news-article-category {
      background-color: $gray-800;
      color: $white;
      padding: 0.5rem 0.5rem;
      border-radius: 8px;
      font-weight: bold;
    }
  }

  .news-read-more-button {
    background-color: $white;
    color: $dark-blue;
    padding: 0.5rem;
    border-radius: 8px;
  }
}
