// Restated Bootstrap Variables

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #28a745 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

$primary: $blue  !default;
$secondary: $gray-600  !default;
$success: $green  !default;
$info: $cyan  !default;
$warning: $yellow  !default;
$danger: $red  !default;
$light: $gray-100  !default;
$dark: $gray-800  !default;


//G&Y Colors

$blue:#4BA1F0;
$blue-8:rgba(#4BA1F0,0.08);
$blue-35:rgba(#4BA1F0,0.35);
$blue-60:rgba(#4BA1F0,0.6);
$dark-blue:#0E364C;
$dark-blue-5:rgba(#0E364C,0.05);
$dark-blue-25:rgba(#0E364C,0.25);
$dark-blue-70:rgba(#0E364C,0.7);
$black:#000000;
$black-20:rgba(#000000,0.2);
$white:#FFFFFF;
$white-5:rgba(#FFFFFF,0.05);
$white-30:rgba(#FFFFFF,0.3);
$grey:#B1B2B5;
$grey-30:rgba(#B1B2B5,0.3);
// $gradient:linear-gradient(227.98deg, rgba(68, 153, 232, 0.3) 30.3%, rgba(50, 115, 175, 0.3) 80.36%);


//Fluid Scaling


$step--3: clamp(0.48rem, calc(0.47rem + 0.04vw), 0.51rem);
$step--2: clamp(0.59rem, calc(0.56rem + 0.11vw), 0.66rem);
$step--1: clamp(0.72rem, calc(0.67rem + 0.22vw), 0.86rem);
$step-0: clamp(0.88rem, calc(0.79rem + 0.38vw), 1.13rem);
$step-1: clamp(1.07rem, calc(0.93rem + 0.60vw), 1.47rem);
$step-2: clamp(1.30rem, calc(1.09rem + 0.92vw), 1.92rem);
$step-3: clamp(1.59rem, calc(1.27rem + 1.37vw), 2.50rem);
$step-4: clamp(1.80rem, calc(1.35rem + 1.93vw), 3.08rem);
$step-5: clamp(2.37rem, calc(1.70rem + 2.84vw), 4.26rem);


$space-3xs: clamp(0.25rem, calc(0.23rem + 0.09vw), 0.31rem);
$space-2xs: clamp(0.44rem, calc(0.39rem + 0.19vw), 0.56rem);
$space-xs: clamp(0.69rem, calc(0.62rem + 0.28vw), 0.88rem);
$space-s: clamp(0.88rem, calc(0.79rem + 0.38vw), 1.13rem);
$space-m: clamp(1.13rem, calc(1.01rem + 0.47vw), 1.44rem);
$space-l: clamp(1.31rem, calc(1.18rem + 0.56vw), 1.69rem);
$space-xl: clamp(1.56rem, calc(1.41rem + 0.66vw), 2.00rem);
$space-2xl: clamp(1.75rem, calc(1.57rem + 0.75vw), 2.25rem);
$space-3xl: clamp(2.00rem, calc(1.80rem + 0.85vw), 2.56rem);
$space-4xl: clamp(2.19rem, calc(1.97rem + 0.94vw), 2.81rem);
$space-5xl: clamp(2.44rem, calc(2.20rem + 1.03vw), 3.13rem);
$space-6xl: clamp(2.63rem, calc(2.36rem + 1.13vw), 3.38rem);
$space-7xl: clamp(2.88rem, calc(2.59rem + 1.22vw), 3.69rem);
$space-8xl: clamp(3.06rem, calc(2.75rem + 1.31vw), 3.94rem);
$space-9xl: clamp(3.31rem, calc(2.98rem + 1.41vw), 4.25rem);
$space-10xl: clamp(3.50rem, calc(3.15rem + 1.50vw), 4.50rem);
$space-11xl: clamp(3.75rem, calc(3.38rem + 1.60vw), 4.81rem);
$space-12xl: clamp(3.94rem, calc(3.54rem + 1.69vw), 5.06rem);
$space-13xl: clamp(4.19rem, calc(3.77rem + 1.78vw), 5.38rem);
$space-14xl: clamp(4.38rem, calc(3.93rem + 1.88vw), 5.63rem);
$space-15xl: clamp(4.63rem, calc(4.16rem + 1.97vw), 5.94rem);
$space-16xl: clamp(4.81rem, calc(4.33rem + 2.07vw), 6.19rem);
$space-17xl: clamp(5.06rem, calc(4.56rem + 2.16vw), 6.50rem);
$space-18xl: clamp(5.25rem, calc(4.72rem + 2.25vw), 6.75rem);


$space-4xl-3xl: clamp(2.19rem, calc(2.06rem + 0.56vw), 2.56rem);
$space-2xs-4xl: clamp(0.44rem, calc(-0.40rem + 3.57vw), 2.81rem);
$space-2xs-3xl: clamp(0.44rem, calc(-0.31rem + 3.19vw), 2.56rem);
$space-s-m: clamp(0.88rem, calc(0.68rem + 0.85vw), 1.44rem);
$space-4xl-6xl: clamp(2.19rem, calc(1.77rem + 1.78vw), 3.38rem);
$space-6xl-18xl: clamp(2.63rem, calc(1.17rem + 6.20vw), 6.75rem);
$space-s-3xl: clamp(0.88rem, calc(0.28rem + 2.54vw), 2.56rem);
$space-l-4xl: clamp(1.31rem, calc(0.78rem + 2.25vw), 2.81rem);
$space-2xs-s: clamp(0.44rem, calc(0.20rem + 1.03vw), 1.13rem);